@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon/icomoon.eot?82mqs1');
  src:  url('./fonts/icomoon/icomoon.eot?82mqs1#iefix') format('embedded-opentype'),
    url('./fonts/icomoon/icomoon.ttf?82mqs1') format('truetype'),
    url('./fonts/icomoon/icomoon.woff?82mqs1') format('woff'),
    url('./fonts/icomoon/icomoon.svg?82mqs1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-C_bw:before {
  content: "\e900";
  color: #fff;
}
.icon-cheqit_bw:before {
  content: "\e901";
  color: #fff;
}
.icon-secondIcon .path1:before {
  content: "\e902";
  color: rgb(255, 255, 255);
}
.icon-secondIcon .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
.icon-thirdIcon .path1:before {
  content: "\e904";
  color: rgb(255, 255, 255);
}
.icon-thirdIcon .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
.icon-firstIcon:before {
  content: "\e906";
}
.icon-fourIcon .path1:before {
  content: "\e907";
  color: rgb(255, 255, 255);
}
.icon-fourIcon .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
